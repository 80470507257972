$blockedColor: #d47e7e;
$processingColor: #ebd897;
$settledColor: #8bba98;

$headerAndFooterBackground: #68aeba;
$borderColor: #68aeba;
$headerAndFooterText: #ffffff;
$titleText: #377a84;
$bodyText: #353d3f;
$continueButtonBackground: #f88010;
$saveButtonBackground: #dbe5e6;
$doneTick: #377a84;
$doneText: #f88010;
$infoBoxBackground: #dbe5e6;
$bullets: #377a84;

$brandBlue: #020887;
$brandBlue_a1: rgba(2, 8, 135, 0.7);
$brandBlue_a2: rgba(2, 8, 135, 0.4);
$brandBlue_a3: rgba(2, 8, 135, 0.1);

$btnRed: #d85454;

$badgeTeal: #41ead4;
$badgeRed: #f50744;
$badgeLightPurple: #9d8df1;

$veryDarkBlue: #042f4c;
$darkBlue: #11659b;
$lightBlue: #e7eff5;
$black: #191919;
$gray: #555555;

$headerHeight: 60px;
$footerClearHeight: 20px;
$footerHeight: 60px;

html {
  scroll-behavior: smooth;
}

body {
  height: 100vh;
  min-width: 320px;
}

#root {
  height: 100%;
}

.no-side-padding {
  padding-left: 0;
  padding-right: 0;
}

.row-rev {
  flex-direction: row-reverse;
}

.BasicDropzone {
  &:hover {
    cursor: pointer;
  }

  & > &-zone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2;
    border-radius: 2;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition-property: color, border;
    transition-duration: 0.24s;
    transition-timing-function: ease-in-out;

    & > p {
      margin: 1rem 0;
    }

    &.accept {
      border-color: $borderColor;
      color: $borderColor;
    }

    &.reject {
      border-color: $btnRed;
      color: $btnRed;
    }

    &.isUploading {
      cursor: initial;
    }
  }
}

.Chevron {
  &-down,
  &-up {
    transition: transform 0.35s ease-out;
  }

  &-up {
    transform: rotate(180deg);
  }
}

.textEditorEditor {
  height: 275px !important;
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

body {
  color: $bodyText;
  font-family: "DM Sans", sans-serif;
}

.hover-visibility-container:hover .hover-visibility-content {
  display: block;
}

.hover-visibility-content {
  display: none;
}

@keyframes highlight-animation {
  0% {
    transform: scale(1);
    box-shadow: none;
    background-color: initial;
  }
  50% {
    transform: scale(1.25);
    box-shadow: 0 0 20px rgba(48, 46, 44, 0.9), 0 0 10px rgba(255, 136, 0, 0.7);
    background-color: #FF8500;
  }
  100% {
    transform: scale(1);
    box-shadow: none;
    background-color: initial;
  }
}

.highlight-button {
  display: inline-block;
  animation: highlight-animation 2s ease-in-out;
}

.required::after{
  content: "*";
  font-size: larger;
}